// App.js
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes,  Navigate } from 'react-router-dom';
import AdminLayout from './components/AdminLayout';
import AddProduct from './components/AddProduct';
import AddUser from './components/AddUser';
import Settings from './components/Setting';
import { lightTheme, darkTheme } from './components/Theme';
import ViewProducts from './components/ViewProducts';
import ViewOrders from './components/ViewOrder';
import { jwtDecode } from 'jwt-decode';
import LoginForm from './components/Login Form';
import { useSelector } from 'react-redux';
import ViewEnquiries from './components/ViewEnquiries';
import AddBracelate from './components/AddBracelate';
import ViewBracelate from './components/ViewBracelate';


function App() {
  // State to manage theme mode
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Toggle function for theme
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); 
  
  console.log("isAuthenticated:", isAuthenticated);  // Debugging step

  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      if (decoded.exp < currentTime) {
        console.log("Token has expired");
        // Handle token expiration, maybe log the user out or refresh the token
      } else {
        console.log("Token is valid");
        // Continue with the authenticated user flow
      }
    }
  }, []);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <Router>
        <Routes>
        <Route path="/" element={<LoginForm />} />
        {isAuthenticated ? (
            <Route path="/" element={<AdminLayout toggleTheme={toggleTheme} isDarkMode={isDarkMode} />}>
            <Route path="adminLayout" element={<AdminLayout toggleTheme={toggleTheme} isDarkMode={isDarkMode} />} />
            <Route path="add-product" element={<AddProduct />} />
            <Route path="settings" element={<Settings />} />
            <Route path="/view-products" element={<ViewProducts isDarkMode={isDarkMode} />} />
            <Route path="/add-user" element={<AddUser />} />
            <Route path="/view-orders" element={<ViewOrders />} />
            <Route path='/view-enquiries' element={<ViewEnquiries/>}></Route>
            <Route path='/add-bracelate' element={<AddBracelate/>}></Route>
            <Route path='/view-bracelate' element={<ViewBracelate/>}></Route>
          </Route>
        ):
          (
            <Route path="*" element={<Navigate to="/" />} />
          )
        }
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
