import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  IconButton,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery, // Import for media queries
} from '@mui/material';
import {
  Brightness4,
  Brightness7,
  AddCircleOutline,
  PersonAdd,
  AccountCircle,
  Settings,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setAuth } from '../Redux/actions';

const AdminLayout = ({ toggleTheme, isDarkMode }) => {
  const drawerWidth = 260; // Drawer width slightly increased for better layout
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); // Check if screen is small

  // State for managing the profile menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false); // State for mobile drawer

  // Handle opening and closing the profile menu
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

 
    const dispatch = useDispatch();
    const navigate = useNavigate();
   

    const handleLogout = async () => {
        try {
            await axios.post('http://localhost:5000/api/logout'); // Call your logout API
            localStorage.removeItem('token');
            dispatch(setAuth(false));
            navigate('/'); // Redirect to login page
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

  // Handle mobile drawer toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box sx={{ textAlign: 'center' }}>
      <Divider />
      <List>
        {/* ListItem for Add Product */}
        <ListItem
          button
          component={Link}
          to="/add-product"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px', // More padding for better spacing
          }}
        >
          <ListItemIcon>
            <AddCircleOutline sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="Add Product"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000', // White in dark mode, black in light mode
              }
            }}
          />
        </ListItem>
        
        <ListItem
          button
          component={Link}
          to="/add-Bracelate"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px', // More padding for better spacing
          }}
        >
          <ListItemIcon>
            <AddCircleOutline sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="Add Bracelate"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000', // White in dark mode, black in light mode
              }
            }}
          />
        </ListItem>
        {/* ListItem for Add User */}
        <ListItem
          button
          component={Link}
          to="/add-user"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px',
          }}
        >
          <ListItemIcon>
            <PersonAdd sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="Add User"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000',
              }
            }}
          />
        </ListItem>

        {/* ListItem for Settings */}
        <ListItem
          button
          component={Link}
          to="/settings"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px',
          }}
        >
          <ListItemIcon>
            <Settings sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="Settings"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000',
              }
            }}
          />
        </ListItem>

        {/* ListItem for View Products */}
        <ListItem
          button
          component={Link}
          to="/view-products"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px',
          }}
        >
          <ListItemIcon>
            <VisibilityOutlined sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="View Products"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000',
              }
            }}
          />
        </ListItem>

        <ListItem
          button
          component={Link}
          to="/view-bracelate"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px',
          }}
        >
          <ListItemIcon>
            <VisibilityOutlined sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="View bracelate"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000',
              }
            }}
          />
        </ListItem>

        {/* ListItem for View Orders */}
        <ListItem
          button
          component={Link}
          to="/view-orders"
          sx={{
            '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
            marginBottom: '0.5rem',
            padding: '12px 20px',
          }}
        >
          <ListItemIcon>
            <VisibilityOutlined sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
          </ListItemIcon>
          <ListItemText
            primary="View Orders"
            sx={{
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                fontSize: '1rem',
                color: isDarkMode ? '#ffffff' : '#000000',
              }
            }}
          />
        </ListItem>

<ListItem
  button
  component={Link}
  to="/view-enquiries"
  sx={{
    '&:hover': { backgroundColor: isDarkMode ? '#444' : '#f0f0f0' },
    marginBottom: '0.5rem',
    padding: '12px 20px',
  }}
>
  <ListItemIcon>
    <VisibilityOutlined sx={{ color: isDarkMode ? '#90caf9' : '#1976d2', fontSize: '1.6rem' }} />
  </ListItemIcon>
  <ListItemText
    primary="View Enquiries"
    sx={{
      '& .MuiTypography-root': {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: isDarkMode ? '#ffffff' : '#000000',
      }
    }}
  />
</ListItem>

      </List>
    </Box>
  );

  

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar with gradient background in dark mode */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: isDarkMode
            ? 'linear-gradient(90deg, #333, #1a1a1a)'
            : '#1976d2',
          transition: 'background-color 0.3s ease',
          boxShadow: isDarkMode
            ? '0 4px 20px rgba(0, 0, 0, 0.7)'
            : '0 4px 20px rgba(0, 0, 0, 0.1)', // Softer shadow for light mode
        }}
      >
        <Toolbar>
          <Typography
            variant="h5"
            noWrap
            sx={{
              flexGrow: 1,
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              color: isDarkMode ? '#90caf9' : '#ffffff', // Color based on theme
              fontSize: '1.6rem', // Larger font size for title
            }}
          >
            Admin Dashboard
          </Typography>
          <IconButton onClick={toggleTheme} color="inherit">
            {isDarkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>

          {/* Profile Icon with Dropdown */}
          <IconButton
            onClick={handleProfileMenuOpen}
            color="inherit"
            sx={{ ml: 2 }} // Margin left for spacing
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>

          {/* Mobile Drawer Toggle Icon */}
          {isMobile && (
            <IconButton onClick={handleDrawerToggle} color="inherit" sx={{ ml: 2 }}>
              <span className="material-icons">menu</span> {/* Hamburger Icon */}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer with improved layout */}
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'} // Use temporary for mobile and permanent for larger screens
        open={isMobile ? mobileOpen : true} // Always open on larger screens
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff',
            color: isDarkMode ? '#ffffff' : '#000000',
            borderRight: '1px solid', // Thin border for separation
            borderColor: isDarkMode ? '#333' : '#e0e0e0', // Adjust border color based on theme
            boxShadow: isDarkMode ? '2px 0px 10px rgba(0,0,0,0.7)' : '2px 0px 10px rgba(0,0,0,0.1)', // Drawer shadow effect
          },
        }}
      >
        <Toolbar />
        {drawer} {/* Drawer content */}
      </Drawer>

      {/* Main content area with more padding and shadow */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 4,
          backgroundColor: isDarkMode ? '#121212' : '#f9f9f9', // Subtle background based on theme
          minHeight: '100vh',
          boxShadow: isDarkMode
            ? '0 4px 12px rgba(0,0,0,0.7)'
            : '0 4px 12px rgba(0,0,0,0.1)', // Box shadow for depth
          borderRadius: '12px', // Soft rounded corners
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
