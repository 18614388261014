import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  IconButton,
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const AddProduct = () => {
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    image: null,
  });
  const [preview, setPreview] = useState(null); // For image preview

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', product.name);
    formData.append('description', product.description);
    formData.append('price', product.price);
    formData.append('image', product.image);

    try {
      const response = await axios.post('https://api.astrodarshan14.com/api/addproduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        alert('Product added successfully!');
        // Clear form and preview
        setProduct({ name: '', description: '', price: '', image: null });
        setPreview(null);
      } else {
        alert('Failed to add product: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('An error occurred while adding the product.');
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setProduct({ ...product, image: files[0] });
      setPreview(URL.createObjectURL(files[0])); // Set the preview URL
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '70vh', padding: '1rem' }}>
      <Grid item xs={12} md={6}>
        <Card
          elevation={6}
          sx={{
            borderRadius: '16px',
            backgroundColor: 'background.paper',
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.12)',
          }}
        >
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: 'center',
                marginBottom: '1.5rem',
                fontWeight: 'bold',
                color: 'primary.main',
              }}
            >
              Add New Product
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Product Name"
                variant="outlined"
                fullWidth
                margin="normal"
                name="name"
                value={product.name}
                onChange={handleChange}
                sx={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Product Description"
                variant="outlined"
                fullWidth
                margin="normal"
                name="description"
                value={product.description}
                onChange={handleChange}
                sx={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Price"
                variant="outlined"
                fullWidth
                margin="normal"
                name="price"
                value={product.price}
                onChange={handleChange}
                sx={{ marginBottom: '1.5rem' }}
              />

              {/* Image Upload Section */}
              <Box sx={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                {preview && (
                  <img
                    src={preview}
                    alt="Product Preview"
                    style={{
                      width: '100%',
                      maxHeight: '250px',
                      objectFit: 'contain',
                      marginBottom: '1rem',
                      borderRadius: '8px',
                    }}
                  />
                )}
                <input
                  accept="image/*"
                  type="file"
                  style={{ display: 'none' }}
                  id="image"
                  name="image"
                  onChange={handleChange}
                />
                <label htmlFor="image">
                  <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                  </IconButton>
                  <Typography variant="body2">Upload Product Image</Typography>
                </label>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  padding: '0.8rem',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Add Product
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AddProduct;
