import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import axios from 'axios';

const ViewEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchEnquiries = async () => {
            try {
                const response = await axios.get('https://api.astrodarshan14.com/api/allenquiries'); // Replace with your API endpoint
                setEnquiries(response.data);
                console.log(response.data)
            } catch (err) {
                console.error('Error fetching enquiries:', err);
                setError('Failed to load enquiries. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchEnquiries();
    }, []);

    if (loading) {
        return <Typography variant="h6">Loading enquiries...</Typography>;
    }

    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    return (
        <TableContainer component={Paper} style={{ maxHeight: '400px',marginLeft:'15vw', width: '60%', margin: '0 auto'  }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '5%' }}><strong>Name</strong></TableCell>
                        <TableCell style={{ width: '5%' }}><strong>Email</strong></TableCell>
                        <TableCell style={{ width: '5%' }}><strong>Message</strong></TableCell>
                        <TableCell style={{ width: '5%' }}><strong>Date</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {enquiries.map((enquiry, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ width: '5%' }}>{enquiry.name}</TableCell>
                            <TableCell style={{ width: '5%' }}>{enquiry.email}</TableCell>
                            <TableCell style={{ width: '5%' }}>{enquiry.message}</TableCell>
                            <TableCell style={{ width: '5%' }}>{new Date(enquiry.date).toLocaleDateString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ViewEnquiries;
