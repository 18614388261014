import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const ViewBracelate = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false); // State for modal open/close
  const [currentProduct, setCurrentProduct] = useState(null); // State for the product being updated

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://api.astrodarshan14.com/api/allbracelate');
        setProducts(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleUpdate = (product) => {
    setCurrentProduct(product); // Set the product to be updated
    setOpen(true); // Open the modal
  };

  const handleDelete = async (id) => {
    try {
      // Send a DELETE request with the product ID
      await axios.post('https://api.astrodarshan14.com/api/deletebracelate', {
        pid: id// Include the product ID in the request body
      });
      
      // Update the state to remove the deleted product
      setProducts(products.filter((product) => product.id !== id));
    } catch (err) {
      setError(err.message);
    }
  };
  

  const handleClose = () => {
    setOpen(false);
    setCurrentProduct(null); // Reset the current product
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData(event.currentTarget); // Create form data

    // Prepare data for update
    const updatedProduct = {
      pid: currentProduct.id,
      name: formData.get('name'),
      price: formData.get('price'),
      // If you are allowing image upload, add that logic here
    };

    try {
      await axios.post('https://api.astrodarshan14.com/api/updatebracelate', updatedProduct); // Adjust endpoint as necessary
      setProducts(products.map((product) => (product.id === currentProduct.id ? { ...product, ...updatedProduct } : product)));
      handleClose(); // Close modal after updating
    } catch (err) {
      setError(err.message); // Handle error
    }
  };

  if (loading) {
    return <Typography variant="h6" textAlign="center">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" textAlign="center" color="error">{error}</Typography>;
  }

  return (
    <Container sx={{ overflowX: 'auto', marginLeft: { xs: '0', md: '230px' }, width: { xs: '100%', md: '150vh' } }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          marginBottom: '1.5rem',
          fontWeight: 'bold',
          color: 'primary.main',
        }}
      >
        View Bracelate
      </Typography>

      <Grid container spacing={2}>
        {products.map((product) => (
          <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
            <Card 
              sx={{ 
                maxWidth: 345, 
                margin: '0 auto', 
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0px 8px 16px rgba(0,0,0,0.2)',
                },
              }}
            >
              <CardMedia
                component="img"
                alt={product.name}
                height="140"
             
                image={`https://api.astrodarshan14.com/${product.image_url}`}
                sx={{ borderRadius: '8px 8px 0 0' }}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {product.name}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {product.price}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {product.description}
                </Typography>
                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => handleUpdate(product)} // Open modal for update
                  >
                    Update
                  </Button>
                  <Button 
                    variant="contained" 
                    color="error" 
                    onClick={() => handleDelete(product.id)} // Call delete function
                  >
                    Delete
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Update Product Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Product</DialogTitle>
        <DialogContent>
          {currentProduct && (
            <form onSubmit={handleSubmit}>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Product Name"
                type="text"
                fullWidth
                defaultValue={currentProduct.name} // Prefill with current product name
                required
              />
              <TextField
                margin="dense"
                name="price"
                label="Product Price"
                type="text"
                fullWidth
                defaultValue={currentProduct.price} // Prefill with current product price
                required
              />
              {/* Uncomment below if image upload is needed */}
              {/* <input
                type="file"
                name="image"
                accept="image/*"
              /> */}
              <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button type="submit" color="primary">Update</Button>
              </DialogActions>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default ViewBracelate;
