import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  CircularProgress,
} from '@mui/material';

const ViewOrders = () => {
  const [orders, setOrders] = useState([]);   // State to hold orders data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null);    // Error state

  // Fetch orders when the component is mounted
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://api.astrodarshan14.com/api/orders');  // Replace with your backend API route
        setOrders(response.data.orders);  // Assuming response has `orders` array
        console.log(response.data.orders)
        setLoading(false);
      } catch (err) {
        setError('orders not found');
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);
  

  // Render a loading spinner while fetching data
  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '50px' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: '20px' }}>
          Loading orders...
        </Typography>
      </Container>
    );
  }

  // Render error message if the request failed
  if (error) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '50px' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }
  

  return (
    <Container sx={{ overflowX: 'auto', padding: { xs: '0 16px', sm: '0 24px' } }}>
      <Typography
        variant="h4"
        gutterBottom
        textAlign="center"
        sx={{
          fontWeight: 'bold',
          color: 'primary.main',
          marginBottom: '1.5rem',
          fontSize: { xs: '1.6rem', sm: '2rem' }, // Adjust font size for mobile
        }}
      >
        View Orders
      </Typography>
      <TableContainer
        component={Paper}
        sx={{ overflowX: 'auto', marginLeft: { xs: '0', md: '230px' }, width: { xs: '100%', md: '150vh' } }}
      >
        <Table sx={{ minWidth: { xs: '400px', md: '700px' } }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Order ID</TableCell>
              <TableCell sx={{ width: '30%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Customer Name</TableCell>
              <TableCell sx={{ width: '30%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Product Name</TableCell>
              <TableCell sx={{ width: '30%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Product Price</TableCell>
              <TableCell sx={{ width: '30%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Customer Phone</TableCell>
              <TableCell sx={{ width: '40%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Order Address</TableCell>
              <TableCell sx={{ width: '10%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Status</TableCell>
              <TableCell sx={{ width: '10%', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order.order_id}</TableCell>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order.customer_name}</TableCell>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order.product_name}</TableCell>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order.order_amount}</TableCell>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order.customer_phone}</TableCell>
                <TableCell  sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
  {order.order_address ? (
    (() => {
      try {
        const address = JSON.parse(order.order_address);  // Parse the JSON string
        return `${address.address_line1 || ''}, 
                ${address.address_line2 || ''}, 
                ${address.city || ''}, 
                ${address.state || ''}, 
                ${address.postal_code || ''}, 
                ${address.country || ''}`;
      } catch (error) {
        console.error('Error parsing address:', error);
        return 'Invalid address format';
      }
    })()
  ) : 'Address not available'}
</TableCell>

                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{order. payment_status}</TableCell>
                <TableCell sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>{new Date(order.createdAt).toISOString().slice(0, 10)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ViewOrders;
