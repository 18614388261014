// components/Settings.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar, // Import Snackbar
  Alert, // Import Alert for Snackbar message
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import axios from 'axios'; // Import axios for API requests

const Settings = ({ isDarkMode }) => {
  const [users, setUsers] = useState([]); // State to store user data
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  
  const navigate = useNavigate(); // Initialize navigation

  // Function to fetch users from the backend
  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://api.astrodarshan14.com/api/getallusers'); // Replace with your API endpoint
      setUsers(response.data); // Set the fetched users to state
    } catch (error) {
      console.error('Error fetching users:', error.message); // Log error
    }
  };
  

  // Function to delete a user
  const handleDelete = async (id) => {
    try {
      await axios.post('https://api.astrodarshan14.com/api/deleteuser', { id }); // Assuming the delete route is POST
      setUsers(users.filter(user => user.id !== id)); // Remove deleted user from the state
      setSnackbarMessage('User deleted successfully!'); // Set success message
      setSnackbarOpen(true); // Open Snackbar
    } catch (error) {
      console.error('Error deleting user:', error);
      setSnackbarMessage('Failed to delete user.'); // Set failure message
      setSnackbarOpen(true); // Open Snackbar
    }
  };

  // Function to handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false); // Close Snackbar
  };

  useEffect(() => {
    fetchUsers(); // Call fetchUsers on component mount
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: 'primary.main',
          textAlign: 'center', 
          fontSize: { xs: '1.5rem', md: '2rem' }, 
        }}
      >
        User Settings
      </Typography>
   
      <Button 
        variant="contained" 
        color="primary" 
        style={{ display: 'block', margin: '20px auto' }} // Testing styles
        onClick={() => navigate('/add-user')}
      >
        Add User
      </Button>
      <TableContainer 
        component={Paper} 
        sx={{ 
          overflowX: 'auto', 
          maxWidth: { xs: '100%', md: '50%' }, 
          margin: 'auto', 
          padding: { xs: '1rem', md: '2rem' },
          borderRadius: '8px', 
        }}
      >
        <Table sx={{ minWidth: 500 }}> 
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell> {/* Added Actions header */}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Button 
                    variant="outlined" 
                    color="error" 
                    onClick={() => handleDelete(user.id)} // Call delete function on click
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for success/failure messages */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} // Automatically hide after 6 seconds
        onClose={handleSnackbarClose} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Positioning the Snackbar
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage} {/* Display the message */}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
