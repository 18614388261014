import React, { useState } from 'react';
import { TextField, Button, Typography, Paper, Grid } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { setAuth } from '../Redux/actions';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.astrodarshan14.com/api/login', {
        email,
        password,
      });
  
      const { token } = response.data;
      localStorage.setItem('token', token);
      dispatch(setAuth(true));
      console.log("Navigating to /adminLayout");
      navigate('/adminLayout', { replace: true }); // Use replace to remove login from history
    } catch (err) {
      console.error("Error occurred during login", err);
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong. Please try again.');
      }
    }
  };
  
  
  return (
    <Paper elevation={3} style={{ padding: '2rem', width: '350px', margin: 'auto', marginTop: '10%' }}>
      <Grid container direction="column" alignItems="center">
        <LockIcon fontSize="large" color="primary" style={{ marginBottom: '1rem' }} />
        <Typography variant="h5" gutterBottom>
          Admin Login
        </Typography>
      </Grid>
      <form onSubmit={handleLogin}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && (
          <Typography color="error" variant="body2" style={{ marginTop: '1rem' }}>
            {error}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '1rem' }}>
          Login
        </Button>
      </form>
      <Typography variant="body2" style={{ marginTop: '1rem', textAlign: 'center' }}>
        <Link to="/forgot-password" style={{ textDecoration: 'none', color: 'blue' }}>
          Forgot Password?
        </Link>
      </Typography>
    </Paper>
  );
};

export default LoginForm;
