// components/AddUser.js
import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, Card, CardContent, Snackbar, Alert } from '@mui/material';
import axios from 'axios'; // Import axios for making API requests

const AddUser = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // State for password
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [message, setMessage] = useState(''); // State for success message

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userData = { username, email, password }; // Prepare user data

    try {
      // Send POST request to the backend
      const response = await axios.post('https://api.astrodarshan14.com/api/registeruser', userData);
      console.log('User added:', response.data); // Log response from backend
      
      // Show success message
      setMessage('User added successfully!');
      setOpenSnackbar(true);

      // Optionally, you can reset the form
      setUsername('');
      setEmail('');
      setPassword('');
    } catch (error) {
      console.error('Error adding user:', error.message); // Log error
      setMessage('Error adding user. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false); // Close Snackbar
  };

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '70vh', padding: '1rem' }}>
      <Grid item xs={12} md={6}>
        <Card 
          elevation={6} 
          sx={{ 
            borderRadius: '16px',
            backgroundColor: 'background.paper', 
            boxShadow: '0 6px 20px rgba(0, 0, 0, 0.12)' 
          }}
        >
          <CardContent>
            <Typography 
              variant="h4" 
              gutterBottom 
              sx={{ 
                textAlign: 'center', 
                marginBottom: '1.5rem',
                fontWeight: 'bold',
                color: 'primary.main'
              }}
            >
              Add New User
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ marginBottom: '1rem' }}
              />
              <TextField
                label="Password"
                variant="outlined"
                type="password" // Set type to password for security
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ marginBottom: '1.5rem' }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  padding: '0.8rem',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Add User
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position of Snackbar
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default AddUser;
